import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { useState } from "react";
import { Alert, Button } from "@material-tailwind/react";
import { FormInput } from "../../components/form-input";
import { toast } from "react-toastify";
import {
  addEmployeeTransaction,
  getCashierDayEndQuery,
} from "../../config/queries";
import { Loading } from "../../components/loading";

type AddSaleFormFields = {
  amount: number;
  comboNum: number;
  note: string;
};

export const CreateForm = () => {
  const { userData } = UserAuth();
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState("");

  const schema = yup.object().shape({
    amount: yup.number().required("You must add an amount"),
    comboNum: yup
      .number()
      .transform((currentValue, originalValue) => {
        return originalValue === "" ? null : currentValue;
      })
      .nullable()
      .typeError("Must be a number"),
    note: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddSaleFormFields>({
    resolver: yupResolver(schema),
  });

  const onAddSale = async (data: AddSaleFormFields) => {
    if (userData) {
      //check if today report has been submitted, if yes prevent user to submit sale
      const querySnapshot = await getCashierDayEndQuery(userData.activeTeam);
      if (querySnapshot.size > 0) {
        setShowAlert(true);
      } else {
        try {
          setLoading(true);
          addEmployeeTransaction(userData, data);
          toast.success("Sale of $" + data.amount + " added!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
          navigate("/");
        } catch (e) {
          if (e instanceof Error) {
            setSubmitError(e.message);
          }
        }
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <div>{submitError && <Alert color="red">{submitError}</Alert>}</div>
      {loading ? (
        <Loading />
      ) : (
        <form onSubmit={handleSubmit(onAddSale)}>
          <FormInput<AddSaleFormFields>
            name="amount"
            label="Amount"
            register={register}
            rules={{ required: "You must enter an amount" }}
            errors={errors}
          />

          <FormInput<AddSaleFormFields>
            name="comboNum"
            label="Combo Num"
            register={register}
            errors={errors}
          />

          <FormInput<AddSaleFormFields>
            name="note"
            label="Note"
            register={register}
            errors={errors}
          />

          <Button type="submit">Submit</Button>
        </form>
      )}
      {showAlert && (
        <div className="m-4 font-bold bg-red-600 rounded">
          <Alert
            animate={{
              mount: { y: 0 },
              unmount: { y: 100 },
            }}
            dismissible={{
              onClose: () => setShowAlert(false),
            }}
            color="red"
          >
            Cashier has closed today sale!!! Talk to Kathy
          </Alert>
        </div>
      )}
    </div>
  );
};
