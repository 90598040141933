import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { Layout } from "./components/layout";
import { Navbar } from "./components/navbar";
import { RequireAuth } from "./components/requireAuth";
import { AddSale } from "./pages/add-sale/add-sale";
import { CashierCompleted } from "./pages/cashier/cashier-completed";
import { Cashier } from "./pages/cashier/cashier";
import { Home } from "./pages/home";
import { Login } from "./pages/login";
import { EmployeeMain } from "./pages/employee/employee-main";
import { Missing } from "./pages/missing";
import { Report } from "./pages/employee/report";
import { SignUp } from "./pages/signup";
import { Unauthorized } from "./pages/unauthorized";
import { Profile } from "./pages/profile/profile";
import { DayEnd } from "./pages/cashier/day-end";
import { AdminSaleReport } from "./pages/team-admin/admin-sale-report";
import { Role } from "./helper/role";
import { ManageUser } from "./pages/user-manager/manage-user";
import { TeamManager } from "./pages/team-manager/team-manager";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SuperAdminSaleReport } from "./pages/super-admin/super-admin-sale-report";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Layout />}>
            {/* public routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/unauthorized" element={<Unauthorized />} />

            {/* private routes */}
            <Route element={<RequireAuth allowedRoles={[Role.Employee]} />}>
              <Route path="/employeemain" element={<EmployeeMain />} />
              <Route path="/addsale" element={<AddSale />} />
              <Route path="/report" element={<Report />} />
            </Route>

            <Route
              element={
                <RequireAuth allowedRoles={[Role.SuperAdmin, Role.Admin]} />
              }
            >
              <Route path="/manageuser" element={<ManageUser />} />
              <Route path="/superadminreport" element={<SuperAdminSaleReport />} />
              <Route path="/adminreport" element={<AdminSaleReport />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/teammanager" element={<TeamManager />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={[Role.Cashier]} />}>
              <Route path="/cashier" element={<Cashier />} />
              <Route path="/cashiercompleted" element={<CashierCompleted />} />
              <Route path="/dayend" element={<DayEnd />} />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    Role.SuperAdmin,
                    Role.Admin,
                    Role.Employee,
                    Role.Cashier,
                  ]}
                />
              }
            >
              <Route path="/" element={<Home />} />
              <Route path="/profile" element={<Profile />} />
            </Route>
            {/* catch all */}
            <Route path="*" element={<Missing />} />
          </Route>
        </Routes>
        <ToastContainer />
      </Router>
    </div>
  );
}

export default App;