import { useEffect, useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { ITransaction } from "../../helper/interfaces";
import { Transaction } from "./transaction";
import { TableHeader } from "./table-header";
import { groupBy, sumOfKey } from "../../helper/funcUtils";
import {
  getCashierQuery,
  toggleCashier,
} from "../../config/queries";
import { Alert, Typography } from "@material-tailwind/react";
import { onSnapshot } from "firebase/firestore";
import { Loading } from "../../components/loading";

interface GroupedTransactionList {
  [key: number]: ITransaction[];
}

export const Cashier = () => {
  const { currentUser, userData } = UserAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [transactionList, setTransactionList] = useState<ITransaction[] | null>(
    null
  );
  const [groupedTransactionList, setGroupedTransactionList] =
    useState<GroupedTransactionList | null>(null);

  const getTransactions = async () => {
    if (currentUser) {
      try {
        setError("");
        setLoading(true);

        onSnapshot(
          getCashierQuery(userData.activeTeam, false),
          (querySnapshot) => {
            let transactionList = querySnapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            })) as ITransaction[];
            setTransactionList(transactionList);
            setGroupedTransactionList(groupBy(transactionList, "comboNum"));
          }
        );        
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message);
        }
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    getTransactions();
  }, []);

  const toggleComplete = async (transactionId: string) => {
    try {
      setError("");
      setLoading(true);
      toggleCashier(userData.activeTeam, transactionId, true);
      if (!transactionList) return;
      const newList = transactionList.filter(
        (transaction) => transaction.id !== transactionId
      );
      setTransactionList(newList);
      setGroupedTransactionList(groupBy(newList, "comboNum"));
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
    }
    setLoading(false);
  };

  return (
    <div className="mx-2">
      {error && <Alert color="red">{error}</Alert>}
      <TableHeader />
      {groupedTransactionList &&
        Object.keys(groupedTransactionList).map((key, index) => {
          return (
            <div key={index}>
              <div className="grid grid-cols-1">
                <div className="my-4 bg-gray-100 rounded-lg shadow">
                  {key !== "null" ? (
                    <div className="flex items-center justify-around space-x-2 text-xl text-red-500 text-bold">
                      <Typography variant="h5"> {"Combo: " + key} </Typography>
                      <Typography variant="h5">
                        {" "}
                        Total: $
                        {sumOfKey(
                          groupedTransactionList[
                            key as unknown as keyof typeof groupedTransactionList
                          ],
                          "amount"
                        )}{" "}
                      </Typography>
                    </div>
                  ) : (
                    <div className="flex items-center justify-around space-x-2 text-xl text-red-500 text-bold">
                      <Typography variant="h5">No Combo</Typography>
                    </div>
                  )}
                  <div>
                    {loading ? (
                      <Loading />
                    ) : (
                      <div>
                        {groupedTransactionList[
                          key as unknown as keyof typeof groupedTransactionList
                        ].map((transaction) => {
                          return (
                            <Transaction
                              key={transaction.id}
                              transaction={transaction}
                              toggleComplete={toggleComplete}
                              isButtonCheck={true}
                              hasEdit={true}
                            />
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
