import { useEffect, useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { ITransaction } from "../../helper/interfaces";
import { addCommas, getTodayDateString, sumBy } from "../../helper/funcUtils";
import { Button, Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "../../components/data-table";
import { getTodayTransactionsForUserDocs } from "../../config/queries";

export const EmployeeMain = () => {
  const { userData } = UserAuth();
  const navigate = useNavigate();
  const [transactionList, setTransactionList] = useState<ITransaction[] | null>(
    null
  );
  const [totalTransactionAmount, setTotalTransactionAmount] = useState(0);
  const getTransactions = async () => {
    if (userData) {
      const data = await getTodayTransactionsForUserDocs(
        userData.activeTeam,
        userData.uid
      );
      const transactionList = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      })) as ITransaction[];

      setTransactionList(transactionList);
      setTotalTransactionAmount(sumBy(transactionList, "amount"));
    }
  };

  useEffect(() => {
    getTransactions();
  }, []);

  const columns = [
    { heading: "", type: "index" },
    { heading: "Time", value: "createdAt", type: "timeStamp" },
    { heading: "Amount", value: "amount", type: "currency" },
    { heading: "Note", value: "note", cellClass: "px-2 py-1 break-all border" },
  ];

  return (
    <div>
      <div className="m-2 border border-b-gray-400">
      <Typography variant="h4" color="red">
          {userData.activeTeam}
        </Typography>
        <Typography variant="h4" color="red">
          Nhớ lấy tip trước khi về
        </Typography>
        <Typography variant="h4" color="red">
          {getTodayDateString()}
        </Typography>
        <Button
          onClick={() => {
            navigate("/addsale");
          }}
        >
          Add Sale
        </Button>
      </div>
      <Typography variant="h4">
        Total sale: {"$ " + addCommas(totalTransactionAmount)}
      </Typography>
      <DataTable data={transactionList} columns={columns} />
    </div>
  );
};
