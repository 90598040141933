import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { doc, setDoc, Timestamp } from "firebase/firestore";
import { db } from "../../config/firebase";
import { Button } from "@material-tailwind/react";
import { FormInput } from "../../components/form-input";
import { CloseModalButton } from "../../components/close-modal-button";

interface Props {
  closeModal: any;
}

type FormFields = {
  name: string;
  id: string;
};

export const AddTeam = (props: Props) => {
  const schema = yup.object().shape({
    name: yup.string().required(),
    id: yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFields>({
    resolver: yupResolver(schema),
  });

  const onAddSale = async (data: FormFields) => {
    const teamsRef = doc(db, "teams", data.id);
    await setDoc(teamsRef, {
      ...data,
      createdAt: Timestamp.fromDate(new Date()),
    });
    closeModal(false);
  };

  const { closeModal } = props;

  return (
    <div>
      <CloseModalButton closeModal={closeModal} />
      <form onSubmit={handleSubmit(onAddSale)}>
        <FormInput<FormFields>
          name="name"
          label="Name"
          register={register}
          errors={errors}
        />

        <FormInput<FormFields>
          name="id"
          label="Company Id"
          register={register}
          errors={errors}
        />

        <Button type="submit">Submit</Button>
      </form>
    </div>
  );
};
