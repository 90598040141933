import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { FirebaseError } from "firebase/app";
import { Alert, Button } from "@material-tailwind/react";
import { FormInput } from "./../components/form-input";
import { UserAuth } from "../context/AuthContext";
import { CloseModalButton } from "../components/close-modal-button";

interface CreateFormData {
  email: string;
}

interface Props {
  closeModal: any;
}

type FormFields = {
  email: string;
};

export const ResetPassword = (props: Props) => {
  const { sendEmailResetPassword } = UserAuth();
  const [error, setError] = useState("");

  const schema = yup.object().shape({
    email: yup.string().email().required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateFormData>({
    resolver: yupResolver(schema),
  });

  const onResetPassword = async (data: CreateFormData) => {
    try {
      await sendEmailResetPassword(data.email);
      closeModal(false);
    } catch (e) {
      const typedError = e as FirebaseError;
      switch (typedError.code) {
        case "auth/wrong-password":
          setError("Incorrect password");
          break;
        case "auth/user-not-found":
          setError("User not found");
          break;
        default:
          setError("Something went wrong: " + e);
      }
    }
  };

  const { closeModal } = props;

  return (
    <div className={"p-2"}>
      <CloseModalButton closeModal={closeModal} />

      {error && <Alert color="red">{error}</Alert>}

      <form onSubmit={handleSubmit(onResetPassword)}>
        <FormInput<FormFields>
          name="email"
          label="Email address"
          register={register}
          errors={errors}
        />

        <Button type="submit">Send password reset email</Button>
      </form>
    </div>
  );
};
