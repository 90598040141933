import { Button, Typography } from "@material-tailwind/react";
import { onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { IUser } from "../../helper/interfaces";
import EditIcon from "@mui/icons-material/Edit";
import { EditUser } from "./edit-user";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { DeleteConfirm } from "../../components/delete-confirm";
import {
  deleteDocWithRef,
  getUserMembershipDocs,
  userNotSuperAdminQuery,
  userRef,
} from "../../config/queries";

export const ManageUser = () => {
  const { currentUser, deleteUser } = UserAuth();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [user, setUser] = useState<IUser | null>(null);
  const [userList, setUserList] = useState<IUser[] | null>(null);

  const [popup, setPopup] = useState<{ show: boolean; user: IUser | null }>({
    show: false,
    user: null,
  });
  // This will show the Confirmation Box
  const handleDelete = (user: IUser | null) => {
    setPopup({
      show: true,
      user,
    });
  };

  // This will perform the deletion and hide the Confirmation Box
  const handleDeleteTrue = async () => {
    if (popup.show && popup.user) {
      if (popup.user) {
        let user = popup.user;
        //remove user from all teams collection by check teams from users collection
        const querySnapshot = await getUserMembershipDocs(user.uid);
        querySnapshot.forEach(async (doc) => {
          await deleteDocWithRef(doc.ref);
        });

        //remove from users collection
        await deleteDocWithRef(userRef(user));

        //remove user from Authentication page
        await deleteUser(user.uid);
        console.log("Deleted user " + user.name);
      }

      setPopup({
        show: false,
        user: null,
      });
    }
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setPopup({
      show: false,
      user: null,
    });
  };

  const getUsers = async () => {
    if (currentUser) {
      onSnapshot(userNotSuperAdminQuery, (querySnapshot) => {
        setUserList(
          querySnapshot.docs.map((doc) => ({
            ...doc.data(),
          })) as IUser[]
        );
      });
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const headers = ["Name", "Email", "Edit", "Delete"];

  return (
    <div>
      {openModal && <EditUser closeModal={setOpenModal} user={user} />}

      {popup.show && (
        <DeleteConfirm
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
        />
      )}

      {!openModal && !popup.show && (
        <div>
          <div className="p-2">
            <Button
              onClick={() => {
                navigate("/signup");
              }}
            >
              Add User
            </Button>
          </div>

          {/*Main table*/}
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left">
              <thead className="text-xs uppercase bg-gray-50">
                <tr className="text-center">
                  {headers.map((header, key) => {
                    return (
                      <th key={key}>
                        <Typography variant="h6">{header}</Typography>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {userList?.map((user, index) => {
                  if (user) {
                    return (
                      <tr key={index}>
                        <td className="px-4 py-2 border">{user.name}</td>
                        <td className="px-4 py-2 border">{user.email}</td>
                        <td className="px-4 py-2 border">
                          <button
                            className="button-edit"
                            onClick={() => {
                              setOpenModal(true);
                              setUser(user);
                            }}
                          >
                            <EditIcon />
                          </button>
                        </td>
                        <td className="px-4 py-2 border">
                          <button
                            onClick={() => {
                              handleDelete(user);
                            }}
                          >
                            <DeleteIcon />
                          </button>
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};
