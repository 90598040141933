import { Button } from "@material-tailwind/react";
import { signOut } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../config/firebase";
import { UserAuth } from "../context/AuthContext";

export const Missing = () => {
  const navigate = useNavigate();
  const signUserOut = async () => {
    await signOut(auth);
    navigate("/login");
  };

  const goToLogin = async () => {
    navigate("/login");
  };

  const { currentUser } = UserAuth();

  return (
    <div>
      <h1>Oops!</h1>
      <p>Page Not Found</p>
      <div className="flexGrow">
        <Link to="/">Visit Our Homepage</Link>
      </div>
      {currentUser ? (
        <div className="px-4 py-2 m-2">
          <Button variant="filled" onClick={signUserOut} size="lg">
            Log Out
          </Button>
        </div>
      ) : (
        <div className="px-4 py-2 m-2">
          <Button variant="filled" onClick={goToLogin} size="lg">
            Go to Login
          </Button>
        </div>
      )}
    </div>
  );
};
