import { UserAuth } from "../context/AuthContext";
import { Role } from "../helper/role";
import { SuperAdminMain } from "./super-admin/super-admin-main";
import { Cashier } from "./cashier/cashier";
import { EmployeeMain } from "./employee/employee-main";
import { TeamAdmin } from "./team-admin/team-admin";
import { Unauthorized } from "./unauthorized";

export const Home = () => {
  const { userData } = UserAuth();
  if (userData.superAdmin) {
    return <SuperAdminMain />;
  } else if (userData?.activeRole === Role.Admin) {
    // team login check
    return <TeamAdmin />;  
  } else if (userData?.activeRole === Role.Employee) {
    // team login check
    return <EmployeeMain />;
  } else if (userData?.activeRole === Role.Cashier) {
    // team login check
    return <Cashier />;
  } else {
    return <Unauthorized />;
  }
};
