import { Typography } from "@material-tailwind/react";

export const TableHeader = () => {
  return (
    <div className="grid grid-cols-5 gap-2 text-center border-b-2 border-gray-600">
      <Typography variant="h6">Cash?</Typography>
      <Typography variant="h6">Time</Typography>
      <Typography variant="h6">Name</Typography>
      <Typography variant="h6">Amount</Typography>
      <Typography variant="h6">Edit</Typography>
    </div>
  );
};
