import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FirebaseError } from "firebase/app";
import { UserAuth } from "../context/AuthContext";
import { FormInput } from "../components/form-input";
import { Alert, Button, Switch } from "@material-tailwind/react";
import { ResetPassword } from "./reset-password";
import { Loading } from "../components/loading";

type FormFields = {
  email: string;
  password: string;  
};

export const Login = () => {
  const { signIn, removeActiveTeam } = UserAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().min(4).max(20).required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFields>({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();

  const onSubmit = async (data: FormFields, e: any) => {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      const result = await signIn(data.email, data.password);
      //update active team on user doc firebase
      await removeActiveTeam(result.user.uid);
      navigate("/");
    } catch (e) {
      const typedError = e as FirebaseError;
      switch (typedError.code) {
        case "auth/wrong-password":
          setError("Incorrect password");
          break;
        case "auth/user-not-found":
          setError("User not found");
          break;
        default:
          setError("Something went wrong: " + e);
      }
    }
    setLoading(false);
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <div>
      {!openModal ? (
        loading ? (
          <Loading />
        ) : (
          <div>
            <div className="max-w-[700px] mx-auto my-4 p-4">
              <h1 className="py-2 text-2xl font-bold">
                Sign in to your account
              </h1>
            </div>
            {error && <Alert color="red">{error}</Alert>}
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormInput<FormFields>
                name="email"
                label="Email"
                register={register}
                errors={errors}
              />

              <FormInput<FormFields>
                name="password"
                label="Password"
                type={passwordShown ? "text" : "password"}
                register={register}
                errors={errors}                     
              />

              <div>
                <Switch
                  label="Show password"
                  onChange={togglePassword}
                  className="flex"
                />
              </div>

              <Button type="submit">Sign In</Button>
            </form>
            <div className="p-2">
              <Button
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                Forgot password
              </Button>
            </div>
          </div>
        )
      ) : (
        <div>
          <ResetPassword closeModal={setOpenModal} />
        </div>
      )}
    </div>
  );
};
