import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInput } from "../components/form-input";
import { Alert, Button, Switch, Typography } from "@material-tailwind/react";

type FormFields = {
  name: string;
  email: string;
  password: string;
};

export const SignUp = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { createUser } = UserAuth();
  const [passwordShown, setPasswordShown] = useState(false);

  const schema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email().required(),
    password: yup.string().min(6).max(20).required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFields>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormFields, e: any) => {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      await createUser(
        data.name,
        data.email,
        data.password
      );      
      navigate("/");
    } catch (e) {
      // Getting the Error details.
      if (e instanceof Error) {
        const message = e.message;
        setError(message);
      }
    }
    setLoading(false);
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <div className="p-4 my-16">
      <div>
        <Typography variant="h4">Sign up</Typography>
      </div>

      {error && <Alert color="red">{error}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput<FormFields>
          name="name"
          label="Name"
          register={register}
          errors={errors}
        />
        <FormInput<FormFields>
          name="email"
          label="Email"
          register={register}
          errors={errors}
        />

        <FormInput<FormFields>
          name="password"
          label="Password"
          type={passwordShown ? "text" : "password"}
          register={register}
          errors={errors}
        />
        <div>
          <Switch
            label="Show password"
            onChange={togglePassword}
            className="flex"
          />
        </div>

        {!loading && <Button type="submit">Sign up</Button>}
      </form>
    </div>
  );
};
