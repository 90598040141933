import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import { useState } from "react";
import { FirebaseError } from "firebase/app";
import { Alert, Button } from "@material-tailwind/react";
import { FormInput } from "../../components/form-input";
import { CloseModalButton } from "../../components/close-modal-button";
import { Loading } from "../../components/loading";

interface Props {
  closeModal: any;
  currentUser: any;
}

type FormFields = {
  currentPassword: string;
  password: string;
  confirmPassword: string;
};

export const ChangePassword = (props: Props) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const schema = yup.object().shape({
    currentPassword: yup.string().min(6).max(20).required(),
    password: yup.string().min(6).max(20).required(),
    confirmPassword: yup.string().oneOf([yup.ref("password"), null]),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFields>({
    resolver: yupResolver(schema),
  });

  const onChangePassword = async (data: FormFields) => {
    if (currentUser) {
      const credential = EmailAuthProvider.credential(
        currentUser.email,
        data.currentPassword
      );
      try {
        setError("");
        setLoading(true);
        await reauthenticateWithCredential(currentUser, credential);
        await updatePassword(currentUser, data.password);
        closeModal(false);
      } catch (e) {
        const typedError = e as FirebaseError;
        switch (typedError.code) {
          case "auth/wrong-password":
            setError("Incorrect password");
            break;
          case "auth/user-not-found":
            setError("User not found");
            break;
          default:
            setError("Something went wrong: " + e);
        }
      }
      setLoading(false);
    }
  };

  const { closeModal, currentUser } = props;

  return (
    <div className={"p-2"}>
      <CloseModalButton closeModal={closeModal} />

      {error && <Alert color="red">{error}</Alert>}

      {loading ? (
        <Loading />
      ) : (
        <form onSubmit={handleSubmit(onChangePassword)}>
          <FormInput<FormFields>
            name="currentPassword"
            label="Current password"
            register={register}
            errors={errors}
          />

          <FormInput<FormFields>
            name="password"
            label="New password"
            register={register}
            errors={errors}
          />

          <FormInput<FormFields>
            name="confirmPassword"
            label="Confirm password"
            register={register}
            errors={errors}
          />

          <Button type="submit">Submit</Button>
        </form>
      )}
    </div>
  );
};
