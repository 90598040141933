import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import { ITransaction } from "../../helper/interfaces";
import { convertDateTime } from "../../helper/funcUtils";
import { EditForm } from "./edit-form";
import { useState } from "react";
import { Button, Typography } from "@material-tailwind/react";

interface Props {
  transaction: ITransaction;
  toggleComplete: (transactionId: string) => void;
  isButtonCheck: boolean;
  hasEdit: boolean;
}

export const Transaction = (props: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const { transaction, toggleComplete, isButtonCheck, hasEdit } = props;

  return (
    <div>
      <div className="grid items-center grid-cols-5 gap-2 my-4 text-center border-b border-gray-400">
        <Button
          color={isButtonCheck ? "green" : "red"}
          onClick={() => toggleComplete(transaction.id)}
        >
          {isButtonCheck ? <DoneIcon /> : <ClearIcon />}
        </Button>
        <Typography variant="h6">
          {" "}
          {convertDateTime(transaction.createdAt)}
        </Typography>
        <Typography variant="h6"> {transaction.userDisplayName}</Typography>
        <Typography variant="h6"> ${transaction.amount}</Typography>
        {hasEdit && (
          <Button
            variant="text"
            onClick={() => {
              setOpenModal(true);
            }}
          >
            <EditIcon />
          </Button>
        )}
      </div>
      {openModal && (
        <EditForm closeModal={setOpenModal} transaction={transaction} />
      )}
    </div>
  );
};
