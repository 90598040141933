import { Button } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

export const SuperAdminMain = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col p-2 m-2">
      <div className="p-2">
        <Button
          onClick={() => {
            navigate("/manageuser");
          }}
        >
          Manage User
        </Button>
      </div>
      
      <div className="p-2">
        <Button
          onClick={() => {
            navigate("/teammanager");
          }}
        >
          Manage Team
        </Button>
      </div>

      <div className="p-2">
        <Button
          onClick={() => {
            navigate("/superadminreport");
          }}
        >
          Today Report
        </Button>
      </div>
    </div>
  );
};
