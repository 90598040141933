import { useState } from "react";

export const useDatePick = () => {
  const [date, setDate] = useState("");

  const setDateState = (e) => {
    setDate(e.target.value);
  };

  return {
    date,
    setDateState,
  };
};
