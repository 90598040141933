import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { doc, Timestamp, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import { ITransaction } from "../../helper/interfaces";
import { FormInput } from "../../components/form-input";
import { Alert, Button, Typography } from "@material-tailwind/react";
import { UserAuth } from "../../context/AuthContext";
import { CloseModalButton } from "../../components/close-modal-button";
import { useState } from "react";
import { Loading } from "../../components/loading";


type FormFields = {
  amount: number;
  comboNum: number;
  note: string;  
};

interface Props {
  transaction: ITransaction;
  closeModal: any;
}

export const EditForm = (props: Props) => {
  const { userData } = UserAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const schema = yup.object().shape({
    amount: yup
      .number()
      .transform((currentValue, originalValue) => {
        return originalValue === "" ? null : currentValue;
      })
      .nullable()
      .typeError("Must be a number"),
    comboNum: yup
      .number()
      .transform((currentValue, originalValue) => {
        return originalValue === "" ? null : currentValue;
      })
      .nullable()
      .typeError("Must be a number"),
    note: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFields>({
    resolver: yupResolver(schema),
  });

  const onEditSale = async (data: FormFields) => {
    const transactionsRef = doc(
      db,
      "userInputs",
      userData.activeTeam,
      "transactions",
      transaction.id
    );

    try {
      setError('');
      setLoading(true);
      await updateDoc(transactionsRef, {
        ...(data.amount !== null && { amount: data.amount }),
        ...(data.comboNum !== null && { comboNum: data.comboNum }),
        ...(data.note !== "" && { note: data.note }),
        updatedAt: Timestamp.fromDate(new Date()),
      });
      closeModal(false);
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
    }
    setLoading(false);
  };

  const { transaction, closeModal } = props;

  return (
    <div>
      {loading ? (
        <Loading/>
      ) : (
        <div className={"bg-white p-2  border-4 border-gray-900"}>
          {error && <Alert color="red">{error}</Alert>}
          <CloseModalButton closeModal={closeModal} />
          <form onSubmit={handleSubmit(onEditSale)}>
            <Typography variant="h6">
              Current user: {transaction.userDisplayName}
            </Typography>
            <Typography variant="h6">
              Current amount: {transaction.amount}
            </Typography>
            <FormInput<FormFields>
              name="amount"
              label="New Amount"
              register={register}
              errors={errors}
            />
            <Typography variant="h6">
              Current combo Num: {transaction.comboNum}
            </Typography>
            <FormInput<FormFields>
              name="comboNum"
              label="New Combo num"
              register={register}
              errors={errors}
            />
            <Typography variant="h6">
              Current note: {transaction.note}
            </Typography>
            <FormInput<FormFields>
              name="note"
              label="New Note"
              register={register}
              errors={errors}
            />

            <Button type="submit">Submit</Button>
          </form>
        </div>
      )}
    </div>
  );
};
