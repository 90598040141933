import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserAuth } from "../context/AuthContext";
import { FormInput } from "../components/form-input";
import { Alert, Button } from "@material-tailwind/react";
import { Loading } from "../components/loading";

type FormFields = {
  team: string;
};

export const TeamLogin = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setActiveTeam, userData } = UserAuth();

  const schema = yup.object().shape({
    team: yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFields>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormFields, e: any) => {
    e.preventDefault();
    setLoading(true);

    if (data.team.toLowerCase() in userData.teams) {
      //update active team on user doc firebase
      await setActiveTeam(data.team.toLowerCase());
      navigate("/");
    } else {
      //show error
      setError("You do not belong to this team");
    }

    setLoading(false);
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <div className="max-w-[700px] mx-auto my-4 p-4">
            <h1 className="py-2 text-2xl font-bold">Sign in to your team</h1>
          </div>

          {error && <Alert color="red">{error}</Alert>}

          <form onSubmit={handleSubmit(onSubmit)}>
            <FormInput<FormFields>
              name="team"
              label="Team Name"
              register={register}
              errors={errors}
            />
            <Button type="submit">Sign In</Button>
          </form>
        </div>
      )}
    </div>
  );
};
