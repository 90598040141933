import { Link } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { Role } from "../helper/role";

export const Navbar = () => {
  const { currentUser, userData } = UserAuth();

  const style = {
    nav: "flex justify-between items-center bg-red-400 h-20 p-2",
    heading: "text-white text-md px-2",
  };

  return (
    <div className="sticky top-0">
      {!currentUser ? (
        <div className="flex items-center justify-end h-20 p-2 bg-red-400">
          <Link className={style.heading} to="/login">
            Login
          </Link>
        </div>
      ) : (
        <div className={style.nav}>
          <div>
            <Link className={style.heading} to="/">
              Home
            </Link>

            {userData && userData.activeRole === Role.Employee && (
              <Link className={style.heading} to="/report">
                Report
              </Link>
            )}

            {userData && userData.activeRole === Role.Cashier && (
              <>
                <Link className={style.heading} to="/cashiercompleted">
                  Cashed Out
                </Link>
                <Link className={style.heading} to="/dayend">
                  End of Day
                </Link>
              </>
            )}
          </div>
          <div>
            <Link className={style.heading} to="/profile">
              Hi, {userData?.name}
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};
