import moment from "moment";

export const getTodayDateString = () => {
  return moment().format('LL');
};

export const getTodayDate = () => {
  return moment().startOf("day").toDate();
};

export const getCurrentTimeDate = () => {
  return moment().toDate();
};

export const getPickedDate = (date: any) => {
  return moment(date).startOf('day').toDate();
};

export const getNextDate = (date: any) => {
  return moment(date).endOf('day').toDate();
};

export const convertDateTime = (date: any) => {
  let newDate = new Date(date * 1000);
  return newDate.getHours() + ":" + reFormatString(newDate.getMinutes());
};

const reFormatString = (data: any) => {
  return (data < 10 ? "0" : "") + data;
};

export const numParser = (num: any): number => {
  return num === "" ? 0 : Number(num);
};

export function sumBy(values: any[], key: string) {
  return values.reduce((total, value) => (total += value[key]), 0);
}

export const addCommas = (num: number) => {
  return num.toLocaleString("en-US");
};

export const groupBy = (array: any[], key: string) => {
  // Return the end result
  return array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
};

// Add their sum of key value
export const sumOfKey = (array: any[], key: string) =>
  array.reduce((sum, currentValue) => {
    return sum + currentValue[key];
  }, 0);

export function sortBy(list: any[], key: string) {
  return list.sort(function (a, b) {
    var keyA = new Date(a[key]),
      keyB = new Date(b[key]);
    // Compare the 2 dates
    if (keyA > keyB) return -1;
    if (keyA < keyB) return 1;
    return 0;
  });
}

export function reorder(array: any[]) {
  var result: any[] = [];
  array.reduce(function (res, value) {
    if (!res[value.userDisplayName]) {
      res[value.userDisplayName] = {
        userDisplayName: value.userDisplayName,
        amount: 0,
      };
      result?.push(res[value.userDisplayName]);
    }
    res[value.userDisplayName].amount += value.amount;
    return res;
  }, {});
  return result;
}
