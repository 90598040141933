import { useContext, createContext, useEffect, useState } from "react";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../config/firebase";
import { doc, updateDoc, onSnapshot } from "firebase/firestore";
import { db, functions } from "../config/firebase";
import { httpsCallable } from "firebase/functions";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const signIn = async (email, password) => {
    return await signInWithEmailAndPassword(auth, email, password);
  };

  const createUserFromCF = httpsCallable(functions, "createUser");
  const deleteUserFromCF = httpsCallable(functions, "deleteUser");

  const createUser = async (name, email, password) => {
    var user = {
      name: name,
      email: email,
      password: password,
    };

    const result = await createUserFromCF(user);
    return result;
  };

  const deleteUser = async (uid) => {
    const result = await deleteUserFromCF({ uid: uid });
    return result;
  };

  const logoutUser = async () => {
    //remove active company and role
    await removeActiveTeam(currentUser.uid);
    signOut(auth);
  };

  const sendEmailResetPassword = async (email) => {
    await sendPasswordResetEmail(auth, email);
  };

  const setActiveTeam = async (team) => {
    const userRef = doc(db, "users", currentUser.uid);
    await updateDoc(userRef, {
      activeTeam: team,
      activeRole: userData.teams[team],
    });    
  };

  const removeActiveTeam = async (userUid) => {
    //remove active company and role
    const userRef = doc(db, "users", userUid);
    await updateDoc(userRef, {
      activeTeam: "",
      activeRole: "",
    });
  };

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {            
      setCurrentUser(user);      
      if (user) {
        setLoading(true);
        user?.getIdTokenResult().then((idTokenResult) => {          
          user.superAdmin = idTokenResult.claims.superadmin;
        });
        onSnapshot(doc(db, "users", user.uid), (doc) => {
          setUserData({ ...doc.data(), superAdmin: user.superAdmin });
        });
      } else {
        setUserData(null);
      }
      setLoading(false);
    });

    return () => {
      unsub();
    };
  }, []);

  const value = {
    currentUser,
    userData,
    loading,
    signIn,
    createUser,
    deleteUser,
    logoutUser,
    sendEmailResetPassword,
    setActiveTeam,
    removeActiveTeam,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};
