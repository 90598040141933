import { Typography } from "@material-tailwind/react";
import { convertDateTime } from "../../helper/funcUtils";
import { IDailyReport } from "../../helper/interfaces";

interface Props {
  report: IDailyReport;
}

export const DailyReport = (props: Props) => {
  const { report } = props;

  return (
    <div>
      {report && (
        <div className="grid grid-cols-1 gap-4 m-2 auto-cols-max">
          <Typography variant="h6">
            Created at: {convertDateTime(report.createdAt)}
          </Typography>

          <Typography variant="h6" color="red">
            Total Sale: {report.totalSale}
          </Typography>
          <Typography variant="h6">Cash: {report.cash}</Typography>
          <Typography variant="h6">Debit: {report.debit}</Typography>
          {report.discount && (
            <Typography variant="h6">Discount: {report.discount}</Typography>
          )}
          {report.gcBuy && (
            <Typography variant="h6">Gift-Card buy: {report.gcBuy}</Typography>
          )}

          {report.gcRedeem && (
            <Typography variant="h6">
              Gift-Card redeem: {report.gcRedeem}
            </Typography>
          )}

          {report.expense && (
            <Typography variant="h6">Expense: {report.expense}</Typography>
          )}

          {report.expenseNote && (
            <Typography variant="h6">
              Expense Note: {report.expenseNote}
            </Typography>
          )}

          {report.otherIncome && (
            <Typography variant="h6">
              Other income: {report.otherIncome}
            </Typography>
          )}

          {report.incomeNote && (
            <Typography variant="h6">
              Income Note: {report.incomeNote}
            </Typography>
          )}

          <Typography variant="h6" color="red">
            Result: {Math.round(report.result)}
          </Typography>
        </div>
      )}
    </div>
  );
};
