import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { addDoc, Timestamp } from "firebase/firestore";
import { useState } from "react";
import { ITransaction } from "../../helper/interfaces";
import { getCurrentTimeDate, numParser, reorder, sumBy } from "../../helper/funcUtils";
import { FormInput } from "../../components/form-input";
import { Alert, Button, Typography } from "@material-tailwind/react";
import { UserAuth } from "../../context/AuthContext";
import {
  cashierDayEndRef,
  getAllTodayTransactionsDocs,
  getCashierDayEndQuery,
} from "../../config/queries";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../components/loading";

type FormFields = {
  cash: number;
  debit: number;
  discount: number;
  gcBuy: number;
  gcRedeem: number;
  expense: number;
  expenseNote: string;
  otherIncome: number;
  incomeNote: string;
};

export const DayEnd = () => {
  const [resultText, setResultText] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { userData } = UserAuth();
  const navigate = useNavigate();

  const numberValidator = yup
    .number()
    .transform((currentValue, originalValue) => {
      return originalValue === "" ? null : currentValue;
    })
    .nullable()
    .typeError("Must be a number");

  const schema = yup.object().shape({
    cash: yup
      .number()
      .required("You must add an amount")
      .typeError("Must be a number"),
    debit: yup
      .number()
      .required("You must add an amount")
      .typeError("Must be a number"),
    discount: numberValidator,
    gcBuy: numberValidator,
    gcRedeem: numberValidator,
    expense: numberValidator,
    expenseNote: yup.string(),
    otherIncome: numberValidator,
    incomeNote: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<FormFields>({
    resolver: yupResolver(schema),
  });

  const calculateRes = async (isSubmitting: boolean) => {
    const data = await getAllTodayTransactionsDocs(userData.activeTeam);
    let transactionList = data.docs.map((doc) => ({
      userDisplayName: doc.data().userDisplayName,
      amount: doc.data().amount,
    })) as ITransaction[];

    let totalByName;

    if (transactionList && isSubmitting) {      
      totalByName = reorder(transactionList);
    }

    let totalSaleVal = sumBy(transactionList, "amount");    
    let result =
      numParser(getValues("cash")) +
      numParser(getValues("debit")) / 1.13 -
      (totalSaleVal -
        numParser(getValues("discount")) +
        numParser(getValues("gcBuy")) / 1.13 -
        numParser(getValues("gcRedeem")) / 1.13 -
        numParser(getValues("expense")) +
        numParser(getValues("otherIncome")));
    return {totalByName: totalByName, result: result, totalSale: totalSaleVal}
  };

  const preCheck = async () => {
    try {
      setLoading(true);
      let res = await calculateRes(false);
      let result = res.result;
      if (result >= 60) {
        setResultText("Over $40 double check!!!");
      } else if (result > 0) {
        setResultText("OK");
      } else {
        setResultText("Thieu $" + (Math.round(result) * -1).toString());
      }
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
    }
    setLoading(false);
  };

  const onSubmit = async (data: FormFields) => {
    const querySnapshot = await getCashierDayEndQuery(userData.activeTeam);
    if (querySnapshot.size > 0) {
      setShowAlert(true);
    } else {
      try {
        setLoading(true);        
        let res = await calculateRes(true);
        await addDoc(cashierDayEndRef(userData.activeTeam), {
          ...data,
          totalByNameList: res.totalByName,
          totalSale: res.totalSale,
          createdAt: Timestamp.fromDate(getCurrentTimeDate()),    
          result: res.result,
        });

        toast.success("Submitted sale for today!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        navigate("/");
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message);
        }
      }
      setLoading(false);
    }
  };

  return (
    <div>
      {showAlert && (
        <div className="m-4 font-bold bg-red-600 rounded">
          <Alert
            animate={{
              mount: { y: 0 },
              unmount: { y: 100 },
            }}
            dismissible={{
              onClose: () => setShowAlert(false),
            }}
            color="red"
          >
            Already submitted sale for today!!! Talk to Kathy
          </Alert>
        </div>
      )}
      {error && <Alert color="red">{error}</Alert>}

      {loading ? (
        <Loading />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInput<FormFields>
            name="cash"
            label="Cash"
            register={register}
            errors={errors}
          />

          <FormInput<FormFields>
            name="debit"
            label="Debit"
            register={register}
            errors={errors}
          />

          <FormInput<FormFields>
            name="discount"
            label="Service discount"
            register={register}
            errors={errors}
          />

          <FormInput<FormFields>
            name="gcBuy"
            label="Gift-Card buy"
            register={register}
            errors={errors}
          />

          <FormInput<FormFields>
            name="gcRedeem"
            label="Gift-Card redeem"
            register={register}
            errors={errors}
          />

          <FormInput<FormFields>
            name="expense"
            label="Expense"
            register={register}
            errors={errors}
          />

          <FormInput<FormFields>
            name="expenseNote"
            label="Expense Note"
            register={register}
            errors={errors}
          />

          <FormInput<FormFields>
            name="otherIncome"
            label="Other income"
            register={register}
            errors={errors}
          />

          <FormInput<FormFields>
            name="incomeNote"
            label="Income Note"
            register={register}
            errors={errors}
          />

          <Button onClick={preCheck}>Check Value before submit</Button>
          <Typography
            variant="h6"
            color={resultText === "OK" ? "green" : "red"}
          >
            Check result: {resultText}
          </Typography>
          <div className="my-6">
            <Button type="submit">Submit</Button>
          </div>
        </form>
      )}
    </div>
  );
};
