import { Input } from "@material-tailwind/react";
import { get } from "lodash";
import {
  DeepMap,
  FieldError,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
} from "react-hook-form";

import { ErrorMessage } from '@hookform/error-message';

export type InputProps = {
  name: string;
  label: string;
  className?: string;
  type?: string;
};

export type FormInputProps<TFormValues extends FieldValues> = {
  name: Path<TFormValues>;
  rules?: RegisterOptions;
  register?: UseFormRegister<TFormValues>;
  errors?: Partial<DeepMap<TFormValues, FieldError>>;
} & Omit<InputProps, "name">;

export const FormInput = <TFormValues extends Record<string, unknown>>({
    name,
    register,
    rules,
    errors,
    className,
    type,
    ...props
}: FormInputProps<TFormValues>): JSX.Element => {
  const errorMessages = get(errors, name);
  const hasError = !!(errors && errorMessages);
  return (
    <div className="p-2">
      <Input
        size="lg"
        type={type}
        name={name}
        error={hasError}        
        {...props}
        {...(register && register(name, rules))}
      />
      <ErrorMessage
        errors={errors}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        name={name as any}
        render={({ message }) => (
          <p className="block mt-1 font-serif text-sm text-left text-red-600">
            {message}
          </p>
        )}
      />
    </div>
  );
};
