import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { Alert, Button, Typography } from "@material-tailwind/react";
import { FormInput } from "../../components/form-input";
import { ITeam } from "../../helper/interfaces";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import { CloseModalButton } from "../../components/close-modal-button";

interface Props {
  team: ITeam | null;
  closeModal: any;
}

export type FormFields = {
  name: string;
};

export const EditTeam = (props: Props) => {
  const [error, setError] = useState("");

  const schema = yup.object().shape({
    name: yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFields>({
    resolver: yupResolver(schema),
  });

  const onEdit = async (data: FormFields) => {
    if (team) {
      try {
        const teamRef = doc(db, "teams", team.id);
        if (data.name !== team.name) {
          //update name
          await updateDoc(teamRef, {
            name: data.name,
          });
        }
      } catch (e) {
        // Getting the Error details.
        if (e instanceof Error) {
          const message = e.message;
          setError(message);
        }
      }

      closeModal(false);
    }
  };

  const { closeModal, team } = props;

  return (
    <div className={"p-2"}>
      <CloseModalButton closeModal={closeModal} />
      {error && <Alert color="red">{error}</Alert>}

      <form onSubmit={handleSubmit(onEdit)}>
        <Typography variant="h6">Current name: {team?.name}</Typography>
        <FormInput<FormFields>
          name="name"
          label="New name"
          register={register}
          errors={errors}
        />

        <Button type="submit">Submit</Button>
      </form>
    </div>
  );
};
