import { Navigate, Outlet, useLocation } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { Missing } from "../pages/missing";

export const RequireAuth = ({
  allowedRoles,
}: {
  allowedRoles: Array<string>;
}) => {
  const location = useLocation();
  const { currentUser, userData } = UserAuth();

  if (userData) {
    return (
      <>
        {userData.superAdmin || allowedRoles.includes(userData.activeRole) ? (
          <Outlet />
        ) : currentUser ? (
          <Navigate to="/unauthorized" state={{ from: location }} replace />
        ) : (
          <Navigate to="/login" state={{ from: location }} replace />
        )}
      </>
    );
  } else {
    return <Missing />;
  }
};
