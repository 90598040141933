import { useEffect, useState } from "react";
import { ITransaction } from "../../helper/interfaces";
import { UserAuth } from "../../context/AuthContext";
import { addCommas, sumBy } from "../../helper/funcUtils";
import { Alert, Button, Input, Typography } from "@material-tailwind/react";
import { DataTable } from "../../components/data-table";
import { getAllTransactionsInDateForUserDocs } from "../../config/queries";
import { useDatePick } from "../../hooks/useDatePick";

export const Report = () => {
  const { userData } = UserAuth();
  const [error, setError] = useState("");
  const [transactionList, setTransactionList] = useState<ITransaction[] | null>(
    null
  );
  const [totalTransactionAmount, setTotalTransactionAmount] = useState(0);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      if (userData && date) {
        const getTransactions = async () => {
          const data = await getAllTransactionsInDateForUserDocs(userData.activeTeam, userData.uid, date);
          setTransactionList(
            data.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            })) as ITransaction[]
          );
        };
        getTransactions();
      }
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
    }
  };

  const setTotalAmount = () => {
    transactionList && setTotalTransactionAmount(sumBy(transactionList, "amount"));
  };

  useEffect(() => {
    setTotalAmount();
  }, [transactionList]);

  const columns = [
    {heading: '', type: 'index'},
    {heading: 'Time', value: 'createdAt', type: 'timeStamp'},
    {heading: 'Amount', value: 'amount', type: 'currency'},
  ]

  const { date, setDateState } = useDatePick();

  return (
    <div>
      {error && <Alert color="red">{error}</Alert>}

      <form onSubmit={handleSubmit}>
        <div className="flex flex-col p-2">
          <Typography variant="h6">Pick a date</Typography>

          <Input
            onChange={setDateState}
            size="lg"
            type="date"
            variant="static"
          />
        </div>
        <Button type="submit">Get report</Button>
      </form>
      <Typography variant="h4">
        Total sale: {"$ " + addCommas(totalTransactionAmount)}
      </Typography>
      <DataTable data ={transactionList} columns={columns} />
    </div>
  );
};
