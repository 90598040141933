import {
  addDoc,
  collection,
  collectionGroup,
  deleteDoc,
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  getDocs,
  limit,
  orderBy,
  Query,
  query,
  startAfter,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  getCurrentTimeDate,
  getNextDate,
  getPickedDate,
  getTodayDate,
} from "../helper/funcUtils";
import { IUser } from "../helper/interfaces";
import { db } from "./firebase";

export const membershipRef = (teamId: string, user: IUser) =>
  doc(db, "teams", teamId, "memberships", user.uid);

export const userRef = (user: IUser) => doc(db, "users", user.uid);

// For cashier pages
const transactionsInTeamRef = (teamId: string) =>
  collection(db, "userInputs", teamId, "transactions");

export const cashierDayEndRef = (teamId: string) => {
  return collection(db, "userInputs", teamId, "cashiers");
};

export const getCashierQuery = (teamId: string, isCashedOut: boolean) => {
  return query(
    transactionsInTeamRef(teamId),
    where("isCashedOut", "==", isCashedOut),
    where("createdAt", ">=", Timestamp.fromDate(getTodayDate())),
    orderBy("createdAt")
  );
};

export const getFirstPageTransactionsQuery = (teamId: string) => {
  return query(
    transactionsInTeamRef(teamId),
    where("isCashedOut", "==", true),
    where("createdAt", ">=", Timestamp.fromDate(getTodayDate())),
    orderBy("createdAt", "desc"),
    limit(10)
  );
};

export const getNextPageTransactionsQuery = (
  teamId: string,
  lastVisible: DocumentSnapshot
) => {
  return query(
    transactionsInTeamRef(teamId),
    where("isCashedOut", "==", true),
    where("createdAt", ">=", Timestamp.fromDate(getTodayDate())),
    orderBy("createdAt", "desc"),
    startAfter(lastVisible),
    limit(10)
  );
};

export const getDocsFromQuery = async (query: Query<DocumentData>) => {
  return await getDocs(query);
};

export const getAllTodayTransactionsDocs = async (teamId: string) => {
  const q = query(
    transactionsInTeamRef(teamId),
    where("createdAt", ">=", Timestamp.fromDate(getTodayDate()))
  );
  return await getDocs(q);
};

export const getTodayTransactionsForUserDocs = async (
  teamId: string,
  userUid: string
) => {
  const q = query(
    collection(db, "userInputs", teamId, "transactions"),
    where("userUid", "==", userUid),
    where("createdAt", ">=", Timestamp.fromDate(getTodayDate())),
    orderBy("createdAt")
  );
  return await getDocs(q);
};

export const addEmployeeTransaction = async (userData: any, data: any) => {
  return await addDoc(transactionsInTeamRef(userData.activeTeam), {
    ...data,
    userDisplayName: userData.name,
    userEmail: userData.email,
    userUid: userData.uid,
    createdAt: Timestamp.fromDate(getCurrentTimeDate()),    
    isCashedOut: false,
  });
};

export const toggleCashier = async (
  teamId: string,
  transactionId: string,
  isCashOut: boolean
) => {
  const transactionRef = doc(
    db,
    "userInputs",
    teamId,
    "transactions",
    transactionId
  );

  await updateDoc(transactionRef, {
    isCashedOut: isCashOut,
  });
};

export const getCashierDayEndQuery = async (teamId: string) => {
  const q = query(
    cashierDayEndRef(teamId),
    where("createdAt", ">=", Timestamp.fromDate(getTodayDate()))
  );

  return await getDocs(q);
};

export const getAllTransactionsInDateForUserDocs = async (
  teamId: string,
  userUid: string,
  date: string
) => {
  const q = query(
    collection(db, "userInputs", teamId, "transactions"),
    where("userUid", "==", userUid),
    where("createdAt", ">=", Timestamp.fromDate(getPickedDate(date))),
    where("createdAt", "<", Timestamp.fromDate(getNextDate(date))),
    orderBy("createdAt")
  );

  return await getDocs(q);
};

export const getAllTransactionsInDateDocs = async (
  teamId: string,
  date: string
) => {
  const q = query(
    collection(db, "userInputs", teamId, "transactions"),
    where("createdAt", ">=", Timestamp.fromDate(getPickedDate(date))),
    where("createdAt", "<", Timestamp.fromDate(getNextDate(date))),
    orderBy("createdAt")
  );

  return await getDocs(q);
};

export const getCashierReportInDateDocs = async (
  teamId: string,
  date: string
) => {
  const q = query(
    collection(db, "userInputs", teamId, "cashiers"),
    where("createdAt", ">=", Timestamp.fromDate(getPickedDate(date))),
    where("createdAt", "<", Timestamp.fromDate(getNextDate(date))),
    orderBy("createdAt")
  );
  return await getDocs(q);
};

export const getUsersDocs = async () => {
  const q = query(collection(db, "users"));
  return await getDocs(q);
};

export const getTeamsDocs = async () => {
  const q = query(collection(db, "teams"), orderBy("name"));
  return await getDocs(q);
};

export const teamRef = (teamId: string) => {
  return doc(db, "teams", teamId);
};

export const deleteDocWithRef = async (docRef: DocumentReference) => {
  return await deleteDoc(docRef);
};

export const getUserMembershipDocs = async (userUid: string) => {
  const q = query(
    collectionGroup(db, "memberships"),
    where("uid", "==", userUid)
  );
  return await getDocs(q);
};

export const userNotSuperAdminQuery = query(
  collection(db, "users"),
  where("name", "!=", "Super Admin"),
  orderBy("name")
);
