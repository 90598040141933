import { Button } from "@material-tailwind/react";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../config/firebase";
import { UserAuth } from "../context/AuthContext";
import { TeamLogin } from "./team-login";

export const Unauthorized = () => {
  const navigate = useNavigate();

  const { userData } = UserAuth();

  const signUserOut = async () => {
    await signOut(auth);
    navigate("/login");
  };

  return (
    <section>
      <h1>Unauthorized</h1>
      <br />
      <p>You do not have access to the requested page.</p>

      <div className="px-4 py-2 m-2">
        <Button variant="filled" onClick={() => navigate(-1)} size="lg">
          Go Back
        </Button>
      </div>

      <div className="px-4 py-2 m-2">
        <Button variant="filled" onClick={signUserOut} size="lg">
          Log Out
        </Button>
      </div>
      {!userData?.activeTeam && (
        <div>
          <TeamLogin />
        </div>
      )}
    </section>
  );
};
