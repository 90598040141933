import { Alert, Button, Input, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { DataTable } from "../../components/data-table";
import {
  getAllTransactionsInDateDocs,
  getCashierReportInDateDocs,
} from "../../config/queries";
import { UserAuth } from "../../context/AuthContext";
import {
  addCommas,
  groupBy,
  reorder,
  sortBy,
  sumBy,
} from "../../helper/funcUtils";
import { IDailyReport, ITransaction } from "../../helper/interfaces";
import { useDatePick } from "../../hooks/useDatePick";
import { AdminSaleReportDetail } from "../team-admin/admin-sale-report-detail";
import { DailyReport } from "../team-admin/daily-report";

export const SuperAdminSaleReport = () => {
  const { userData } = UserAuth();
  const [error, setError] = useState("");
  const [transactionList, setTransactionList] = useState<ITransaction[] | null>(
    null
  );
  const [totalTransactionAmount, setTotalTransactionAmount] = useState(0);

  const [report, setReport] = useState<IDailyReport | null>(null);
  const [totalByNameList, setTotalByNameList] = useState<any[] | null>(null);
  const [groupByNameList, setGroupByNameList] = useState<any[] | null>(null);
  const [detailName, setDetailName] = useState<string>("");
  const [openModal, setOpenModal] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);

  const options = [
    { value: "", text: "--Choose team--" },
    { value: "glamournaillounge", text: "Glamour Nail Lounge" },
    { value: "glamournailbar", text: "Glamour Nail Bar" }
  ];
  const [teamSelected, setTeamSelected] = useState(options[0].value);

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    try {
      if (userData && date) {
        const getTransactions = async () => {
          let teamId = teamSelected;
          let reportData = await getAllTransactionsInDateDocs(teamId, date);
          setTransactionList(
            reportData.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            })) as ITransaction[]
          );

          if (transactionList) {
            setTotalByNameList(reorder(transactionList));
            setGroupByNameList(groupBy(transactionList, "userDisplayName"));
          }
        };

        const getDailyReport = async () => {
          let teamId = teamSelected;
          let reportData = await getCashierReportInDateDocs(teamId, date);
          if (reportData && reportData.docs.length > 0) {
            setIsEmpty(false);
            setReport(reportData.docs[0].data() as IDailyReport);
          } else {
            setIsEmpty(true);
            setReport(null);
          }
        };

        getDailyReport();
        getTransactions();
      }
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
    }
  };

  const handleSelectTeam = (event: any) => {
    setTeamSelected(event.target.value);
  };

  const setTotalAmount = () => {
    if (transactionList) {
      setTotalTransactionAmount(sumBy(transactionList, "amount"));
      setTotalByNameList(sortBy(reorder(transactionList), "amount"));
      setGroupByNameList(groupBy(transactionList, "userDisplayName"));
    }
  };

  useEffect(() => {
    setTotalAmount();
  }, [transactionList]);

  const columns = [
    { heading: "Name", value: "userDisplayName" },
    { heading: "Amount", value: "amount", type: "currency" },
  ];

  const handleOnClick = (item: any) => {
    setDetailName(item.userDisplayName);
    setOpenModal(true);
  };

  const { date, setDateState } = useDatePick();

  return (
    <div>
      {!openModal ? (
        <div>
          {error && <Alert color="red">{error}</Alert>}

          <form onSubmit={handleFormSubmit}>
            <div className="flex flex-col p-2">
              <Typography variant="h6">Pick a date</Typography>

              <Input
                onChange={setDateState}
                size="lg"
                type="date"
                variant="static"
              />
            </div>

            <div className="relative w-full lg:max-w-sm">
              <select
                className="w-full p-2.5 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
                value={teamSelected}
                onChange={handleSelectTeam}
              >
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>

            <Button type="submit">Get report</Button>
          </form>
          <Typography variant="h6">{teamSelected}</Typography>
          <Typography variant="h4">Date: {date}</Typography>
          <Typography variant="h4">
            Total sale: {"$ " + addCommas(totalTransactionAmount)}
          </Typography>

          {isEmpty && <Alert color="red">NO DAILY REPORT DATA</Alert>}
          {report && <DailyReport report={report} />}

          {transactionList && (
            <DataTable
              data={totalByNameList}
              columns={columns}
              onClick={handleOnClick}
            />
          )}
        </div>
      ) : (
        <div>
          {openModal && (
            <AdminSaleReportDetail
              closeModal={setOpenModal}
              data={
                groupByNameList &&
                groupByNameList[detailName as keyof typeof groupByNameList]
              }
            />
          )}
        </div>
      )}
    </div>
  );
};
