import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { FirebaseError } from "firebase/app";
import { Alert, Button } from "@material-tailwind/react";
import { FormInput } from "../../components/form-input";
import { CloseModalButton } from "../../components/close-modal-button";
import { Loading } from "../../components/loading";
import { UserAuth } from "../../context/AuthContext";

interface Props {
  closeModal: any;
  currentUser: any;
}

type FormFields = {
  team: string;
};

export const ChangeTeam = (props: Props) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { setActiveTeam, userData } = UserAuth();

  const schema = yup.object().shape({
    team: yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFields>({
    resolver: yupResolver(schema),
  });

  const onChangeTeam = async (data: FormFields) => {
    if (currentUser) {
      try {
        setError("");
        setLoading(true);
        let teamId = data.team;
        if (teamId.toLowerCase() in userData.teams) {
          //update active team on user doc firebase
          await setActiveTeam(teamId.toLowerCase());
          closeModal(false);
        } else {
          //show error
          setError("You do not belong to this team");
        }
      } catch (e) {
        const typedError = e as FirebaseError;
        switch (typedError.code) {
          case "auth/wrong-password":
            setError("Incorrect password");
            break;
          case "auth/user-not-found":
            setError("User not found");
            break;
          default:
            setError("Something went wrong: " + e);
        }
      }
      setLoading(false);
    }
  };

  const { closeModal, currentUser } = props;

  return (
    <div className={"p-2"}>
      <CloseModalButton closeModal={closeModal} />

      {error && <Alert color="red">{error}</Alert>}

      {loading ? (
        <Loading />
      ) : (
        <form onSubmit={handleSubmit(onChangeTeam)}>
          <FormInput<FormFields>
            name="team"
            label="Team id"
            register={register}
            errors={errors}
          />
          <Button type="submit">Submit</Button>
        </form>
      )}
    </div>
  );
};
