import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ClearSharpIcon from "@mui/icons-material/ClearSharp";
import { useState } from "react";
import { Alert, Button, Typography } from "@material-tailwind/react";
import { FormInput } from "../../components/form-input";
import { IUser } from "../../helper/interfaces";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebase";

interface Props {
  user: IUser | null;
  closeModal: any;
}

type FormFields = {
  name: string;
};

export const EditUser = (props: Props) => {
  const [error, setError] = useState("");

  const schema = yup.object().shape({
    name: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFields>({
    resolver: yupResolver(schema),
  });

  const onEdit = async (data: FormFields) => {
    if (user) {
      const userRef = doc(db, "users", user.uid);
      if (data.name !== "" && data.name !== user.name) {
        //update name
        await updateDoc(userRef, {
          name: data.name,
        });
      }
      closeModal(false);
    }
  };

  const { closeModal, user } = props;

  return (
    <div className={"p-2"}>
      <div className="p-2 text-xl text-right">
        <Button
          variant="filled"
          onClick={() => closeModal(false)}
          size="sm"
          color="red"
        >
          <ClearSharpIcon />
        </Button>
      </div>

      {error && <Alert color="red">{error}</Alert>}

      <form onSubmit={handleSubmit(onEdit)}>
        <Typography variant="h6">Current name: {user?.name}</Typography>
        <Typography variant="h6">Current email: {user?.email}</Typography>
        <FormInput<FormFields>
          name="name"
          label="New name"
          register={register}
          errors={errors}
        />
        <Button type="submit">Submit</Button>
      </form>
    </div>
  );
};
