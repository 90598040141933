import { QueryDocumentSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { ITransaction } from "../../helper/interfaces";
import { Transaction } from "./transaction";
import { TableHeader } from "./table-header";
import {
  getDocsFromQuery,
  getFirstPageTransactionsQuery,
  getNextPageTransactionsQuery,
  toggleCashier,
} from "../../config/queries";
import { Button, Alert } from "@material-tailwind/react";
import { Loading } from "../../components/loading";

export const CashierCompleted = () => {
  const { currentUser, userData } = UserAuth();
  const [transactionList, setTransactionList] = useState<ITransaction[] | null>(
    null
  );
  const [lastDoc, setLastDoc] = useState<QueryDocumentSnapshot>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  const [isLastDoc, setIsLastDoc] = useState(false);

  const getTransactions = async () => {
    if (currentUser) {
      // Query the first page of docs
      const first = getFirstPageTransactionsQuery(userData.activeTeam);
      const documentSnapshots = await getDocsFromQuery(first);

      if (documentSnapshots.size === 0) {
        setIsEmpty(true);
      } else {
        updateState(documentSnapshots);
      }
    }
  };

  const updateState = (documentSnapshots: any) => {
    const newTransactionList = documentSnapshots.docs.map((doc: any) => ({
      ...doc.data(),
      id: doc.id,
    })) as ITransaction[];
    setTransactionList(
      transactionList
        ? [...transactionList, ...newTransactionList]
        : newTransactionList
    );
    const lastVisible =
      documentSnapshots.docs[documentSnapshots.docs.length - 1];

    setLastDoc(lastVisible);
  };

  const getNextPage = async () => {
    if (lastDoc && transactionList) {
      const next = getNextPageTransactionsQuery(userData.activeTeam, lastDoc);
      const documentSnapshots = await getDocsFromQuery(next);
      if (documentSnapshots.size === 0) {
        setIsLastDoc(true);
      } else {
        updateState(documentSnapshots);
        setIsLastDoc(false);
      }
    }
  };

  useEffect(() => {
    getTransactions();
  }, []);

  const toggleComplete = async (transactionId: string) => {
    try {
      setError("");
      setLoading(true);
      toggleCashier(userData.activeTeam, transactionId, false);
      if (!transactionList) return;
      const newList = transactionList.filter(
        (transaction) => transaction.id !== transactionId
      );
      setTransactionList(newList);
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
    }
    setLoading(false);
  };

  return (
    <div className="mx-2">
      {loading ? (
        <Loading />
      ) : (
        <div>
          {error && <Alert color="red"> {error}</Alert>}
          <TableHeader />
          <div>
            {transactionList?.map((transaction, index) => (
              <Transaction
                key={index}
                transaction={transaction}
                toggleComplete={toggleComplete}
                isButtonCheck={false}
                hasEdit={false}
              />
            ))}
          </div>
          {!isEmpty && !isLastDoc && (
            <Button onClick={getNextPage}> get more</Button>
          )}
          {isEmpty && <Alert> No Data yet</Alert>}
          {isLastDoc && <Alert color="red"> No more Data</Alert>}
        </div>
      )}
    </div>
  );
};
