import { Typography, Button } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { ITeam } from "../../helper/interfaces";
import { UserAuth } from "../../context/AuthContext";
import { EditTeam } from "./edit-team";
import { EditTeamMember } from "./edit-team-member";
import DeleteIcon from "@mui/icons-material/Delete";
import { AddTeam } from "./add-team";
import { DeleteConfirm } from "../../components/delete-confirm";
import { deleteDocWithRef, teamRef } from "../../config/queries";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../config/firebase";

export const TeamManager = () => {
  const { currentUser } = UserAuth();
  const [openTeamModal, setOpenTeamModal] = useState(false);
  const [openTeamMemberModal, setOpenTeamMemberModal] = useState(false);
  const [openAddTeamModal, setOpenAddTeamModal] = useState(false);

  const [popup, setPopup] = useState<{ show: boolean; team: ITeam | null }>({
    show: false,
    team: null,
  });
  // This will show the Confirmation Box
  const handleDelete = (team: ITeam | null) => {
    setPopup({
      show: true,
      team,
    });
  };

  // This will perform the deletion and hide the Confirmation Box

  const handleDeleteTrue = async () => {
    if (popup.show && popup.team) {
      //remove from teams collection
      if (popup.team) {
        await deleteDocWithRef(teamRef(popup.team.id));
      }
      setPopup({
        show: false,
        team: null,
      });
    }
  };

  // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setPopup({
      show: false,
      team: null,
    });
  };

  const [team, setTeam] = useState<ITeam | null>(null);
  const [teamId, setTeamId] = useState<string | null>(null);
  const [teamList, setTeamList] = useState<ITeam[] | null>(null);

  const getTeams = async () => {
    if (currentUser) {
      const teamQuery = query(collection(db, "teams"), orderBy("name"));

      onSnapshot(teamQuery, (querySnapshot) => {
        setTeamList(
          querySnapshot.docs.map((doc) => ({
            ...doc.data(),
          })) as ITeam[]
        );
      });
    }
  };

  useEffect(() => {
    getTeams();
  }, []);

  const headers = ["Team Name", "Edit", "Members", "Delete"];
  return (
    <div>
      {openTeamModal && <EditTeam closeModal={setOpenTeamModal} team={team} />}
      {openTeamMemberModal && (
        <EditTeamMember closeModal={setOpenTeamMemberModal} teamId={teamId} />
      )}

      {openAddTeamModal && <AddTeam closeModal={setOpenAddTeamModal} />}
      {popup.show && (
        <DeleteConfirm
          handleDeleteTrue={handleDeleteTrue}
          handleDeleteFalse={handleDeleteFalse}
        />
      )}

      {/*Main table*/}
      {!openTeamModal &&
        !openTeamMemberModal &&
        !openAddTeamModal &&
        !popup.show && (
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left">
              <thead className="text-xs uppercase bg-gray-50">
                <tr className="text-center">
                  {headers.map((header, key) => {
                    return (
                      <th key={key}>
                        <Typography variant="h6">{header}</Typography>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {teamList?.map((team, index) => {
                  if (team) {
                    return (
                      <tr key={index}>
                        <td className="px-4 py-2 border">{team.name}</td>
                        <td className="px-4 py-2 border">
                          <button
                            className="button-edit"
                            onClick={() => {
                              setOpenTeamModal(true);
                              setTeamId(team.id);
                            }}
                          >
                            <EditIcon />
                          </button>
                        </td>
                        <td className="px-4 py-2 border">
                          <button
                            onClick={() => {
                              setOpenTeamMemberModal(true);
                              setTeamId(team.id);
                            }}
                          >
                            <PeopleAltIcon />
                          </button>
                        </td>
                        <td className="px-4 py-2 border">
                          <button
                            onClick={() => {
                              handleDelete(team);
                            }}
                          >
                            <DeleteIcon />
                          </button>
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
            <div className="p-2">
              <Button
                onClick={() => {
                  setOpenAddTeamModal(true);
                }}
              >
                Add Team
              </Button>
            </div>
          </div>
        )}
    </div>
  );
};
