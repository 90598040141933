import ClearSharpIcon from "@mui/icons-material/ClearSharp";
import { Button } from "@material-tailwind/react";

export const CloseModalButton = (props: any) => {  
  return (
    <div className="p-2 text-xl text-right">
      <Button
        variant="filled"
        onClick={() => props.closeModal(false)}
        size="sm"
        color="red"
      >
        <ClearSharpIcon />
      </Button>
    </div>
  );
};
