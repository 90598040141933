import { Typography } from "@material-tailwind/react";
import { addCommas, convertDateTime } from "../helper/funcUtils";
import { ITransaction, ITransactionKeys } from "../helper/interfaces";

interface Column {
  heading: string;
  value?: string;
  type?: string;
  cellClass?: string;
}

export const DataTable = ({
  data,
  columns,
  onClick,
}: {
  data: any;
  columns: Column[];
  onClick?: any;
}) => {
  return (
    <div>
      <table className="w-full mx-auto overflow-hidden bg-white divide-y divide-gray-300 rounded-lg table-auto">
        <thead className="text-xs uppercase bg-gray-50">
          <tr className="text-center">
            {columns.map((item: Column, index: number) => (
              <TableHeadItem key={index} item={item} />
            ))}
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item: ITransaction, index: number) => (
              <TableRow
                key={index}
                item={item}
                columns={columns}
                rowIndex={index}
                onClick={onClick}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
};

const TableHeadItem = ({ item }: { item: Column }) => (
  <th>
    <Typography variant="h6">{item.heading}</Typography>
  </th>
);

const TableRow = ({
  item,
  columns,
  rowIndex,
  onClick,
}: {
  item: ITransaction;
  columns: Column[];
  rowIndex: number;
  onClick: any;
}) => (
  <tr className="break-words">
    {columns.map((columnItem, index) => {
      type keyVal = keyof typeof ITransactionKeys;
      let cellValue = item[columnItem.value as keyVal];

      switch (columnItem.type) {
        case "timeStamp":
          cellValue = convertDateTime(cellValue);
          break;
        case "index":
          cellValue = rowIndex + 1;
          break;
        case "currency":
          cellValue = "$ " + addCommas(cellValue as number);
          break;
      }

      return (
        <td
          className={columnItem.cellClass || "px-2 py-1 border"}
          key={index}
          onClick={() => onClick(item)}
        >
          {cellValue}{" "}
        </td>
      );
    })}
  </tr>
);
