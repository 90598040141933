import { Button } from "@material-tailwind/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { EditTeamMember } from "../team-manager/edit-team-member";

export const TeamAdmin = () => {
  const navigate = useNavigate();
  const { userData } = UserAuth();
  const [openTeamMemberModal, setOpenTeamMemberModal] = useState(false);
  const [teamId, setTeamId] = useState<string | null>(null);

  return (
    <div className="flex flex-col p-2 m-2">
      {openTeamMemberModal ? (
        <EditTeamMember closeModal={setOpenTeamMemberModal} teamId={teamId} />
      ) : (
        <div>          
          <div className="p-2">
            <Button
              onClick={() => {
                setOpenTeamMemberModal(true);
                setTeamId(userData.activeTeam);
              }}
            >
              Edit team member
            </Button>
          </div>
          <div className="p-2">
            <Button
              onClick={() => {
                navigate("/adminreport");
              }}
            >
              Sale Report
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
