import { CloseModalButton } from "../../components/close-modal-button";
import { DataTable } from "../../components/data-table";
import { ITransaction } from "../../helper/interfaces";

const columns = [
  { heading: "", type: "index" },
  { heading: "Time", value: "createdAt", type: "timeStamp" },
  { heading: "Name", value: "userDisplayName" },
  { heading: "Amount", value: "amount", type: "currency" },
];

export const AdminSaleReportDetail = ({
  data,
  closeModal,
}: {
  data: ITransaction[];
  closeModal: any;
}) => {
  return (
    <div>
      <CloseModalButton closeModal={closeModal} />
      <DataTable data={data} columns={columns} />
    </div>
  );
};
