import { Button, Typography } from "@material-tailwind/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../components/loading";
import { UserAuth } from "../../context/AuthContext";
import { ChangePassword } from "./change-password";
import { ChangeTeam } from "./change-team";

export const Profile = () => {
  const navigate = useNavigate();
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [openTeamModal, setOpenTeamModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userData, currentUser, logoutUser } = UserAuth();

  const signUserOut = async () => {
    try {
      setLoading(true);
      await logoutUser();
      navigate("/login");
    } catch (e) {}
    setLoading(false);
  };

  return (
    <div>
      {!openPasswordModal &&
        !openTeamModal &&
        (loading ? (
          <Loading />
        ) : (
          <div className="flex flex-col">
            <div className="m-2">
              <Typography variant="h3">Team: {userData.activeTeam}</Typography>
            </div>
            <div className="px-4 py-2 m-2">
              <Button
                onClick={() => {
                  setOpenTeamModal(true);
                }}
              >
                Change team
              </Button>
            </div>
            <div className="px-4 py-2 m-2">
              <Button
                onClick={() => {
                  setOpenPasswordModal(true);
                }}
              >
                Change password
              </Button>
            </div>
            <div className="px-4 py-2 m-2">
              <Button onClick={signUserOut}>Log Out</Button>
            </div>
          </div>
        ))}
      {openPasswordModal && (
        <ChangePassword
          closeModal={setOpenPasswordModal}
          currentUser={currentUser}
        />
      )}

      {openTeamModal && (
        <ChangeTeam closeModal={setOpenTeamModal} currentUser={currentUser} />
      )}
    </div>
  );
};
