import { useEffect, useState } from "react";
import { Typography } from "@material-tailwind/react";
import { IUser } from "../../helper/interfaces";
import {
  deleteDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { getUsersDocs, membershipRef, userRef } from "../../config/queries";
import { CloseModalButton } from "../../components/close-modal-button";

interface Props {
  teamId: string | null;
  closeModal: any;
}

export const EditTeamMember = (props: Props) => {
  const options = [
    { value: "", text: "--Choose role option--" },
    { value: "employee", text: "Employee" },
    { value: "cashier", text: "Cashier" },
    { value: "admin", text: "Admin" },
  ];

  const [roleSelected, setRoleSelected] = useState(options[0].value);
  const [userList, setUserList] = useState<IUser[] | null>(null);
  const [otherUserList, setOtherUserList] = useState<IUser[] | null>(null);
  const { closeModal, teamId } = props;

  const getUserList = async () => {
    if (teamId) {
      const data = await getUsersDocs();
      const allUserList = data.docs.map((doc) => ({
        ...doc.data(),
      })) as IUser[];

      setUserList(
        allUserList.filter((user) => user.teams && teamId in user.teams)
      );

      setOtherUserList(
        allUserList.filter((user) => user.teams && !(teamId in user.teams) && user.name !== 'Super Admin')
      );
    }
  };

  useEffect(() => {
    getUserList();
  }, []);

  const onAddMember = async (teamId: string | null, user: IUser) => {
    if (teamId) {
      // add to memberships of company
      const currentTeams = user.teams;
      const roleToAdd = roleSelected === "" ? "employee" : roleSelected;
      currentTeams[teamId] = roleToAdd;
      const docData = {
        email: user.email,
        name: user.name,
        role: roleToAdd,
        uid: user.uid,
        teams: currentTeams,
      };
      await setDoc(membershipRef(teamId, user), docData);

      //add company info to user data
      await updateDoc(userRef(user), {
        teams: currentTeams,
      });

      //remove from Other users list
      if (otherUserList)
        setOtherUserList(
          otherUserList.filter((userObj) => userObj.uid !== user.uid)
        );

      //add to current team list
      if (user) {
        setUserList((prev) => (prev ? [...prev, user] : [user]));
      }
    }
  };

  const onRemoveMember = async (teamId: string | null, user: IUser) => {
    if (teamId) {
      //remove from memberships of company
      const currentTeams = user.teams;
      delete currentTeams[teamId];

      await deleteDoc(membershipRef(teamId, user));
      //remove company info on user data under "users" collection
      await updateDoc(userRef(user), {
        teams: currentTeams,
      });

      //remove from current users list
      if (userList)
        setUserList(userList.filter((userObj) => userObj.uid !== user.uid));

      //add to other team list
      if (otherUserList) {
        setOtherUserList((prev) => (prev ? [...prev, user] : [user]));
      }
    }
  };

  const handleSelectRole = (event: any) => {
    setRoleSelected(event.target.value);
  };

  const headers1 = ["Name", "Email", "Role", "Edit", "Remove"];
  const headers2 = ["Name", "Email", "Add"];

  console.log(teamId);

  return (
    <div className={"p-2"}>
      <CloseModalButton closeModal={closeModal} />

      {/*Main table*/}
      <Typography variant="h4">{teamId}</Typography>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left">
          <thead className="text-xs uppercase bg-gray-50">
            <tr className="text-center">
              {headers1.map((header, key) => {
                return (
                  <th key={key}>
                    <Typography variant="h6">{header}</Typography>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {userList?.map((user, index) => {
              if (user && teamId) {
                return (
                  <tr key={index}>
                    <td className="px-4 py-2 border">{user.name}</td>
                    <td className="px-4 py-2 border">{user.email}</td>
                    <td className="px-4 py-2 border">{user.teams[teamId]}</td>
                    <td className="px-4 py-2 border">
                      <button className="button-edit" onClick={() => {}}>
                        <EditIcon />
                      </button>
                    </td>
                    <td className="px-4 py-2 border">
                      <button
                        className="button-delete"
                        onClick={() => {
                          onRemoveMember(teamId, user);
                        }}
                      >
                        <DeleteIcon />
                      </button>
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>

      {/*Other users table*/}
      <Typography variant="h4">Other users</Typography>

      <div className="relative w-full lg:max-w-sm">
        <select
          className="w-full p-2.5 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
          value={roleSelected}
          onChange={handleSelectRole}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </select>
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left table-auto">
          <thead className="text-xs uppercase bg-gray-50">
            <tr className="text-center">
              {headers2.map((header, key) => {
                return (
                  <th key={key}>
                    <Typography variant="h6">{header}</Typography>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {otherUserList?.map((user, index) => {
              if (user) {
                return (
                  <tr key={index}>
                    <td className="px-4 py-2 border">{user.name}</td>
                    <td className="px-4 py-2 border">{user.email}</td>
                    <td className="px-4 py-2 border">
                      <button
                        onClick={() => {
                          onAddMember(teamId, user);
                        }}
                      >
                        <AddCircleIcon />
                      </button>
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
